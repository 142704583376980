<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          订单编号：
          <el-input v-model="ordersNo"  placeholder="请输入订单编号"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          下单时间：
          <el-date-picker
            style="width: 100%"
            v-model="pickerValue"
            align="right"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          用户姓名：
          <el-input v-model="nickeName"  placeholder="请输入用户姓名"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          联系方式：
          <el-input v-model="phone"  placeholder="请输入用户手机号"></el-input>
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          商品名称：
          <el-input v-model="goodsName"  placeholder="请输入商品名称"></el-input>
        </div>
      </el-col>
      <el-col :xl="18" :lg="12">
        <div class="flex-a-b-c pt10">
          <div>
            订单状态：
            <el-radio
              style="margin-right: 0;"
              v-model="orderStatusValue"
              :label="item.label"
              v-for="(item, index) in orderStatus"
              :key="index"
              border
            >
              {{ item.text }}
            </el-radio>
            订单类型：
            <el-radio
                    style="margin-right: 0; margin-top: 10px"
                    v-model="orderTypeValue"
                    :label="item.label"
                    v-for="(item, index) in orderType"
                    :key="index"
                    border
            >
              {{ item.text }}
            </el-radio>
            <el-button type="primary" class="ml10"  @click="selectOrdersInfoPcAsync">搜索</el-button>
            <div>


            </div>

          </div>

          <div>
            <!-- <el-button style="padding-top: 0; padding-bottom: 0" class="export" @click="exportOrder">
              <div
                style="height: 32px"
                class="d-flex align-items-center justify-content-center"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-save"></use>
                </svg>
                <span>导出</span>
              </div>
            </el-button> -->
          </div>

        </div>

      </el-col>

      <!-- <el-col :xl="6" :lg="6">
        <div class="d-flex align-items-center mt10 nowrap">
          <span>订单编号：</span>
          <el-input placeholder="请输入订单编号" />
        </div>
      </el-col> -->


    </el-row>
    <el-row :gutter="24">
      <!-- <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          用户姓名：
          <el-input placeholder="请输入用户姓名" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          联系方式：
          <el-input placeholder="请输入用户手机号" />
        </div>
      </el-col>
      <el-col :xl="4" :lg="4">
        <div class="d-flex align-items-center mt10 nowrap">
          商品名称：
          <el-input placeholder="请输入商品名称" />
        </div>
      </el-col> -->
      <!-- <el-col :xl="12" :lg="12">
        <div class="d-flex align-items-center mt10 nowrap">
          <el-button type="primary" @click="selectOrdersInfoPcAsync">搜索</el-button>
        </div>
      </el-col> -->
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <el-table-column prop="ordersId" width="100" align="center" label="序号" />
        <el-table-column prop="ordersNo" align="center" width="160px" label="订单编号" />
        <!-- <el-table-column
          prop="productPicture"
          align="center"
          label="商品图片"
          show-overflow-tooltip
        /> -->
        <el-table-column prop="nickeName" align="center" label="用户姓名" />
        <el-table-column
          prop="phone"
          align="center"
          width="120"
          label="收货人电话"
        />
        <el-table-column prop="locationAddress" align="center" width="200px" show-overflow-tooltip  label="收货地址">
          <template slot-scope="scope"
            ><span >{{
              scope.row.locationAddress +  scope.row.detailedAddress
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" align="center" show-overflow-tooltip label="商品名称" />
        <el-table-column prop="orderPrice" align="center" label="订单金额" />
        <el-table-column prop="specAttribute" align="center" label="产品规格" />
        <el-table-column prop="num" align="center" label="订单数量" />
        <!-- <el-table-column prop="shipping" align="center" label="运费" /> -->
        <!-- <el-table-column prop="distribution" align="center" label="分销佣金" />
        <el-table-column prop="nickname" align="center" label="分销人昵称" /> -->
        <el-table-column prop="createTime" align="center"  show-overflow-tooltip label="下单时间">
          <template slot-scope="scope"
            ><span >{{
              scope.row.createTime | setDate()
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="订单状态">
          <template slot-scope="scope"
            ><span>{{
              scope.row | setState()
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assembleId" align="center" label="订单来源">
        <template slot-scope="scope"
            ><span>{{
               scope.row.assembleId  ? '拼团订单' : '个人订单'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" />
        <el-table-column  width="150px" align="center" label="操作">
          <template slot-scope="scope">
            <span class="cblue hover_pointer" v-if="scope.row.orderState == 0" @click="delExpireOrder(scope.row.ordersId)">
                删除
              </span>
              <span class="cblue hover_pointer" v-else-if="scope.row.orderState == 1" @click="onConfirmMsg(scope.row.ordersId, 5, 1)">
                撤销订单
              </span>
              <span class="cblue hover_pointer" v-else-if="scope.row.orderState == 2 && scope.row.refundState == 1" @click="onConfirmMsg(scope.row.ordersId, 3, 1)">
                确认发货
              </span>
              <template v-else-if="scope.row.refundState == 2">
                <span class="cblue hover_pointer" @click="onConfirmMsg(scope.row.ordersId, 1, 2)">
                  拒绝退款
                </span>
                <span class="cblue hover_pointer" @click="onConfirmMsg(scope.row.ordersId, 3, 2)">
                  确定退款
                </span>
              </template>
              <template v-else>-</template>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectOrdersInfoPc,
  updateOrderState,
  delOrders,
  updateRefundState,
  exportOrdersInfoPc
} from "@/api/order";
import { getDataTimeSec } from "@/utils";
export default {
  name: "orderList",
  components: {
    commonTable,
  },
  data() {
    return {
      orderStatusValue: '', //订单状态
      orderTypeValue: "", //订单类型
      pickerValue: "", //下单时间
      ordersNo:'',//订单编号
      goodsName:'',//商品名称
      phone:'',
      nickeName:'',
      searchCondition:'', //搜索时间
      spanArr: [], //二维数组，用于存放单元格合并规则
      position: 0, //用于存储相同项的开始index
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
      start:'',
      end:'',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      orderStatus: [
        //订单状态
        { text: "全部订单", label: "" },
        { text: "待付款", label: 1 },
        { text: "待发货", label: 2 },
        { text: "待收货", label: 3 },
        { text: "已完成", label: 4 },
      ],
      orderType: [
        //订单类型
        { text: "全部订单", label: "1" },
        { text: "分销订单", label: "2" },
        { text: "普通订单", label: "3" },
        { text: "拼团订单", label: "4" },
      ],
      tableData: [],
    };
  },
  created() {
    this.selectOrdersInfoPcAsync();
    this.rowspan(0, "orderNumber");
    this.rowspan(8, "nameOrtelephone");
    this.rowspan(9, "orderType");
  },
  watch:{
    pickerValue(val,old){
      console.log(val)
      if(val == null) {
       return this.searchCondition = ''
      }
      this.searchCondition = getDataTimeSec(val, 'yy-mm-dd') +" 00:00:00"
      // this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
      // this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59"
      // console.log(this.start)
      // console.log(this.end)
    }
  },
  filters: {
    setState(item) {
       let obj = {
            1: {
              0: '已失效',
              1: '待付款',
              2: '待发货',
              3: '待收货',
              4: '已完成',
              5: '订单取消',
              6: '退款成功',
            },
            2: '退款中',
            3: '退款成功',
          };
          return item.refundState == 1 ? obj[item.refundState][item.orderState] : obj[item.refundState];
    },
    setDate(date){
     return getDataTimeSec(date)
    }
  },
  methods: {
    //导出
    exportOrder(){
      let data = {
        orderState:''
      }
      exportOrdersInfoPc(data).then(res=>{
        let blob = new Blob(["\ufeff",res],{ type: 'text/csv,charset=UTF-8'});

        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          window.navigator.msSaveOrOpenBlob(blob, 'fileName.csv');
        } else {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'fileName.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        // const fileName = '订单列表.xls'
        // if ('download' in document.createElement('a')) { // 非IE下载
        //   const elink = document.createElement('a')
        //   elink.download = fileName
        //   elink.style.display = 'none'
        //   elink.href = URL.createObjectURL(blob)
        //   document.body.appendChild(elink)
        //   elink.click()
        //   URL.revokeObjectURL(elink.href) // 释放URL 对象
        //   document.body.removeChild(elink)
        // } else { // IE10+下载
        //   navigator.msSaveBlob(blob, fileName)
        // }

        let link = document.createElement('a');
        let url = window.URL.createObjectURL(res);
        link.href = url;
        link.download = '订单列表.xls';

        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(err=>{
        console.log(err)
      })
    },
    rowspan(idx, prop) {
      this.spanArr[idx] = [];
      this.position = 0;
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr[idx].push(1);
          this.position = 0;
        } else {
          if (this.tableData[index][prop] === this.tableData[index - 1][prop]) {
            this.spanArr[idx][this.position] += 1; //有相同项
            this.spanArr[idx].push(0); // 名称相同后往数组里面加一项0
          } else {
            this.spanArr[idx].push(1); //同列的前后两行单元格不相同
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ([0, 8, 9].includes(columnIndex)) {
        const _row = this.spanArr[columnIndex][rowIndex];
        const _col = _row > 0 ? 1 : 0;
        // console.log('第'+rowIndex+'行','第'+i+'列','rowspan:'+_row,'colspan:'+_col)
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    /**@method 获取订单列表 */
    async selectOrdersInfoPcAsync() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize:this.pageSize,
        createTimes: this.searchCondition,
        orderState: this.orderStatusValue,
        orderType:this.orderTypeValue,
        ordersNo:this.ordersNo,
        goodsName:this.goodsName,//商品名称
        phone:this.phone,
        nickeName:this.nickeName,
      };
      const result = await selectOrdersInfoPc(data);
      this.loading = false
      if(result.data){

        this.tableData = result.data.pageInfo.list;
        this.total = result.data.pageInfo.total
        this.tableData.forEach(v=>{
          v.orderPrice = (v.orderPrice/100).toFixed(2)
        })
      }else{
        this.tableData = []
      }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    //删除订单
    delExpireOrder(id){
      this.$confirm('确定要删除这个订单吗？', "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
      .then(() => {
        delOrders({ordersId: id})
        .then(res=>{
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.selectOrdersInfoPcAsync()
        })
      })
      .catch((err) => {
        console.log(err);
      });

    },
    //修改订单状态
    onConfirmMsg(ordersId, state, type) {
      let obj = {
        1: {
          5: '确定要撤销该订单吗？',
          3: '是否确定发货？',
        },
        2: {
          1: '是否拒绝退款？',
          3: '是否确定退款',
        }
      };
      let text = obj[type][state];

      this.$confirm(text, "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
      .then(() => {
        if (type == 1) {
          this.changeOrderState(ordersId, state);
        } else {
          this.changeRefund(ordersId, state);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    changeOrderState(id,state){
      updateOrderState({
        orderState: state,
        ordersId: id,
      })
      .then(res=>{
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.selectOrdersInfoPcAsync()
      })
    },
    changeRefund(id,state){
       updateRefundState({
        refundState : state,
        ordersId: id,
      })
      .then(res=>{
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.selectOrdersInfoPcAsync()
      })
    }
  },
};
</script>

<style scoped lang='scss'>
.orderList-view {
  padding-top: 14px;
}
</style>
